
import { defineComponent } from 'vue';
import baseSubscription from '@/components/view/common/subscription';
import changeTextByScene from '@/methods/setText';

export default defineComponent({
    components: {
        baseSubscription
    },
    setup() {
        changeTextByScene('community');

        return {
        };
    }
});
